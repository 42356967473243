import React, { useMemo } from "react"
import PageRoot from "../components/PageRoot"
import { graphql, navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { CERTIFICATION, DIPLOMA, MASTER } from "../enums/CertificationTypes"
import AtsGrid from "../components/AtsGrid"
import ContainerWithPadding from "../components/ContainerWithPadding"
import CertificationTile from "../components/CertificationTile"
import withOwnershipCheck from "../components/withOwnershipCheck"
import withCertificationState from "../components/withCertificationState"
import { translatePath } from "../utils"
import withTileBadgeCheck from "../components/withTileBadgeCheck"
import { CertificationBadge } from "../components/Labels"
import { Col, Row } from "antd"

const AllCertificationPage = ({ data }) => {
  const { t } = useTranslation()

  const headerData = {
    title: t("title:heroCertificationTitle1"),
    subtitle: t("title:heroCertificationSubtitle"),
    description: t("title:heroCertificationDescription"),
    wallpaper: "/assets/images/wall-cert-3d.png",
    actions: [],
  }

  const renderCertificationTile = (item, index) => {
    const TileComponent = withCertificationState(
      withTileBadgeCheck(withOwnershipCheck(CertificationTile))
    )

    return (
      <TileComponent
        key={index}
        data={item}
        onClick={() => {
          navigate(`${translatePath("/certification")}/${item.seo.slug}`)
        }}
        extra={[
          <CertificationBadge badge={item.badge} title={item.badge_title} />,
        ]}
      />
    )
  }

  const certificationRows = useMemo(() => {
    const certifications = data.ats.certifications.filter(
      cert => cert.type === CERTIFICATION
    )

    const diplomas = data.ats.certifications.filter(
      cert => cert.type === DIPLOMA
    )

    const masters = data.ats.certifications.filter(cert => cert.type === MASTER)

    return (
      <>
        {certifications.length > 0 && (
          <AtsGrid
            title={t("label:certifications")}
            dataSource={certifications}
            renderItem={renderCertificationTile}
          />
        )}
        {diplomas.length > 0 && (
          <AtsGrid
            title={t("label:diplomas")}
            dataSource={diplomas}
            renderItem={renderCertificationTile}
          />
        )}
        {masters.length > 0 && (
          <AtsGrid
            title={t("label:masters")}
            dataSource={masters}
            renderItem={renderCertificationTile}
          />
        )}
      </>
    )
  }, [data])

  return (
    <PageRoot title={t("label:myCertifications")}>
      <div className="page-title-wrapper">
        <Row justify="center">
          <Col xs={22} sm={22} md={20} lg={18} xl={12} xxl={12}>
            <h1>{headerData.title}</h1>
            <h3
              style={{
                color: "#969696",
                textTransform: "uppercase",
                fontWeight: "300",
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              {headerData.subtitle}
            </h3>
            <p>{headerData.description}</p>
          </Col>
        </Row>
      </div>

      <ContainerWithPadding size="large">
        {certificationRows}
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query allCertifications {
    ats {
      certifications(where: { state: { _eq: PUBLISHED } }) {
        ...PreviewCardCertification
      }
    }
  }
`

export default AllCertificationPage
